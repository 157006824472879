import axios from 'axios';
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
});

instance.interceptors.request.use(config => {
  const authToken = store.getters.accessToken;
  if (authToken) {
    config.headers.Authorization = 'Bearer ' + authToken;
  }
  return config;
})
export default instance;
