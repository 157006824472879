<template>
   <b-row class="mt-2">
    <b-col>
      <b-input-group>
        <b-input @keypress.enter="search" v-model="text" ref="searchInput" autofocus />
        <b-button @click="clean" title="Pulisci">
          <b-icon icon="x"></b-icon>
        </b-button>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      text: ''
    }
  },
  methods: {
    search() {
      this.searchItems(this.text)
    },
    clean() {
      this.text = ''
      this.search()
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
    ...mapActions([
      'searchItems'
    ])
  }
}
</script>
