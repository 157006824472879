import Vue from 'vue'
import Vuex from 'vuex'
import itemService from '@/services/itemService'
import userService from '../services/userService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    itemList: [],
    extraItemList: [],
    loadedItem: { state: 'create', item: { name: '', quantity: 0, buyNow: false } },
    buyNowEnabled: false,
    // autenticazione
    accessToken: window.localStorage.getItem('accessToken'),
    profile: window.localStorage.getItem('profile')
  },
  mutations: {
    newItem(state, item) {
      state.itemList.push(item);
    },
    newExtraItem(state, item) {
      state.extraItemList.push(item)
    },
    loadItem(state, itemState) {
      state.loadedItem = itemState;
    },
    modifyItem(state, { item, index }) {
      state.itemList.splice(index, 1, item);
    },
    removeItem(state, index) {
      state.itemList.splice(index, 1);
    },
    removeExtraItem(state, index) {
      state.extraItemList.splice(index, 1)
    },
    init(state, items) {
      state.itemList = []
      state.itemList.push(...items);
    },
    initExtra(state, items) {
      state.itemList = []
      state.extraItemList.push(...items);
    },
    setItems(state, items) {
      state.itemList = items.slice(0)
    },
    setBuyNowFlag(state, value) {
      state.buyNowEnabled = value
    },
    // authentication
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    resetAccessToken(state) {
      state.accessToken = null;
    },
    resetProfile(state) {
      state.profile = null;
    }
  },
  actions: {
    register(ctx, account) {
      return userService.save(account.user, account.passwd)
    },
    fetchItems(ctx) {
      itemService.fetch()
        .then(items => ctx.commit('init', items))
    },
    fetchExtraItems(ctx) {
      itemService.fetchExtra()
        .then(items => ctx.commit('initExtra', items))
    },
    saveItem(ctx, { state, item, index }) {
      if (state === 'edit') {
        itemService.edit(item)
          .then(res => { console.log(JSON.stringify(res)); ctx.commit('modifyItem', { item: res, index }) })
      } else {
        itemService.save(item)
          .then(res => ctx.commit('newItem', res))
      }
    },
    removeItem(ctx, { item, index }) {
      itemService.remove(item)
        .then(res => ctx.commit('removeItem', index))
        .catch(err => console.log(err));
    },
    saveExtraItem(ctx, item) {
      itemService.saveExtra(item)
        .then(res => ctx.commit('newExtraItem', res))
    },
    removeExtraItem({ commit }, { item, index }) {
      itemService.remove(item)
        .then(res => commit('removeExtraItem', index))
        .catch(err => console.log(err));
    },
    loadItem(ctx, itemState) {
      ctx.commit('loadItem', itemState);
    },
    unloadItem(ctx) {
      ctx.commit('loadItem', { state: 'create', item: { name: '', quantity: 0, buyNow: false } });
    },
    searchItems({ commit }, text) {
      itemService.search({ text })
        .then(data => commit('setItems', data))
    },
    filterBy({ commit }, filter) {
      itemService.search({ filter })
        .then(data => commit('setItems', data))
    },
    sortBy({ commit }, sort) {
      itemService.search({ sort })
        .then(data => commit('setItems', data))
    },
    buyNowFilter({ commit }, value) {
      commit('setBuyNowFlag', value)
    },
    // autenticazione
    authenticateUser(ctx, { accessToken, profile }) {
      ctx.commit('setAccessToken', accessToken);
      ctx.commit('setProfile', profile);
    },
    logout(ctx) {
      ctx.commit('resetAccessToken');
      ctx.commit('resetProfile');
    }
  },
  getters: {
    itemList(store) {
      return store.itemList
    },
    extraItemList(store) {
      return store.extraItemList
    },
    loadedItem(store) {
      return store.loadedItem
    },
    buyNowFilter(store) {
      return store.buyNowEnabled
    },
    // authentication
    profile(state) {
      return state.profile
    },
    accessToken(state) {
      return state.accessToken
    },
    isAuthenticated(state) {
      return state.profile !== null
    }
  },
  modules: {
  }
})
