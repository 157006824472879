import axios from 'axios';
import store from '@/store';
import router from '@/router';

const serviceUrl = 'api/cambusa/register';

const save = (cambusaName, passwd) => {
  return axios.post(serviceUrl, { username: cambusaName, password: passwd })
    .then(res => res.data)
};

const authenticate = (user) => {
  return axios.post(
    '/api/cambusa/auth/login',
    {
      username: user.nickname,
      password: user.password
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(resp => {
    const accessToken = resp.data.token;
    store.dispatch('authenticateUser', { accessToken, profile: user.nickname });
    window.localStorage.setItem('accessToken', accessToken);
    window.localStorage.setItem('profile', user.nickname);
    return true;
  })
    .catch(err => {
      if (err.response) { // response sent
        if (err.response.status === 401) {
          console.log('autenticazione fallita');
        }
      } else { // response not sent
        console.log('problemi comunicazione server');
      }
      return false;
    });
};

const isAuthenticated = () => {
  return store.getters.isAuthenticated;
};

const logout = () => {
  store.dispatch('logout');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('profile');
  router.push('/login');
};

const isRegistrationOpen = () => {
  return axios.get('/api/cambusa/config')
    .then(resp => resp.data['is-registration-open'])
};

const userService = {
  save,
  authenticate,
  isAuthenticated,
  logout,
  isRegistrationOpen
};

export default userService;
