<template>
  <b-row class="mt-3">
    <b-col>
      <b-button @click="addItem" variant="primary" block>Aggiungi</b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  methods: {
    addItem() {
      this.$store.dispatch('unloadItem');
      this.$bvModal.show('item-form');
    }
  }
};
</script>
