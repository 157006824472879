<template>
  <div>
    <b-row class="mt-2">
      <b-col>
        <b-button-group>
          <b-button v-b-modal.filter-modal size="sm">
            <b-icon icon="funnel-fill"></b-icon>
          </b-button>
          <b-button v-b-modal.sort-modal size="sm">
            <b-icon icon="arrow-down-up"></b-icon>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-modal
        id="filter-modal"
        title="Filtra per"
        hide-footer
        no-close-on-backdrop
        hide-header-close
    >
      <b-form-radio-group
        v-model="filterBy"
        :options="filterOptions"
        @change="filter"
        stacked
      >
      </b-form-radio-group>
    </b-modal>

        <b-modal
        id="sort-modal"
        title="Ordina per"
        hide-footer
        no-close-on-backdrop
        hide-header-close
    >
      <b-form-radio-group
        v-model="sortBy"
        :options="sortOptions"
        @change="sort"
        stacked
      >
      </b-form-radio-group>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      filterBy: 'all',
      filterOptions: [
        { text: 'Tutto', value: 'all' },
        { text: 'Compra subito', value: 'buyNow' }
      ],
      sortBy: 'quantity',
      sortOptions: [
        { text: 'Quantità', value: 'quantity' },
        { text: 'Z-A', value: 'name-desc' },
        { text: 'A-Z', value: 'name-asc' }
      ]
    }
  },
  methods: {
    filter() {
      this.filterItemsBy(this.filterBy)
      this.buyNowFilter(this.filterBy === 'buyNow')
      this.$bvModal.hide('filter-modal')
    },
    sort() {
      this.sortItemsBy(this.sortBy)
      this.$bvModal.hide('sort-modal')
    },
    ...mapActions({
      filterItemsBy: 'filterBy',
      sortItemsBy: 'sortBy',
      buyNowFilter: 'buyNowFilter'
    })
  }
}
</script>
