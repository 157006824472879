import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import store from '@/store'
import userService from '../services/userService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      protected: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      protected: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      protected: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login' && store.getters.isAuthenticated) {
    next('/');
  } else if (to.matched.some(record => record.meta.protected)) {
    if (!userService.isAuthenticated()) {
      next('/login')
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
