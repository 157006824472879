import axios from '@/services/httpService';

const serviceUrl = 'api/cambusa/item/';

const save = (item) => {
  return axios.post(serviceUrl, item)
    .then(res => res.data)
    .catch(err => console.error(err))
};

const saveExtra = (item) => {
  item.extra = true
  return axios.post(serviceUrl, item)
    .then(res => res.data)
    .catch(err => console.error(err))
};

const fetch = () => {
  return axios.get(serviceUrl)
    .then(res => res.data)
    .catch(err => console.error(err))
}

const fetchExtra = () => {
  const config = {
    params: {
      extra: true
    }
  }
  return axios.get(serviceUrl, config)
    .then(res => res.data)
    .catch(err => console.error(err))
}

const edit = (item) => {
  return axios.put(serviceUrl + item._id, item)
    .then(res => res.data)
    .catch(err => console.error(err))
}

const remove = (item) => {
  return axios.delete(serviceUrl + item._id)
    .catch(err => console.error(err))
}

const search = (params = {}) => {
  const config = {
    params: {
      filter: params.text,
      category: params.filter && params.filter !== 'all',
      sort: params.sort
    }
  }
  // workaround
  if (config.params.category === false) {
    config.params.category = undefined
  }
  if (config.params.sort === 'quantity') {
    config.params.sort = undefined
  }
  return axios.get(serviceUrl, config)
    .then(res => res.data)
    .catch(err => console.error(err))
}

const itemService = {
  save,
  saveExtra,
  fetch,
  fetchExtra,
  edit,
  remove,
  search
};

export default itemService;
