<template>
  <b-navbar variant="primary" type="dark" sticky>
      <b-nav-text right v-if="profile !== null">
        Ti trovi in {{profile}} <b-button type="link" @click="logout">esci</b-button>
      </b-nav-text>
  </b-navbar>
</template>

<script>
import userService from '../services/userService'
export default {
  name: 'Header',
  data() {
    return {
      profile: window.localStorage.getItem('profile')
    }
  },
  methods: {
    logout() {
      userService.logout();
    }
  }
}
</script>
