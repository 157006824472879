<template>
  <b-modal
    id="item-form"
    title="Aggiungi"
    cancel-title="Annulla"
    no-close-on-backdrop
    hide-header-close
    @show="cleanMessages"
  >
    <b-alert :show="showErrors" variant="danger">
      {{errorMsg}}
   </b-alert>
    <b-form-group id="fieldset-1" label="Prodotto" label-for="input-1">
      <b-form-input id="input-1" v-model="itemState.item.name" trim></b-form-input>
    </b-form-group>
    <b-form-group id="fieldset-2" label="Quantità" label-for="input-2">
      <b-form-input
        id="input-2"
        type="number"
        min="0"
        v-model.number="itemState.item.quantity"
        trim
      ></b-form-input>
    </b-form-group>
    <b-button-group>
      <buy-now :flag="itemState.item.buyNow" @buy-now="update" />
    </b-button-group>
    <template v-slot:modal-footer>
      <b-col class="clearfix">
        <b-button @click="remove" variant="danger" class="float-left" v-if="isEditState">Elimina</b-button>
        <b-button @click="add" class="float-right" variant="primary">{{okButtonLabel}}</b-button>
        <b-button @click="cancel" class="float-right mr-1">Annulla</b-button>
      </b-col>
    </template>
  </b-modal>
</template>

<script>
import BuyNow from '@/components/BuyNow'

export default {
  data() {
    return {
      errorMsg: ''
    }
  },
  computed: {
    itemState() {
      return this.$store.getters.loadedItem
    },
    isEditState() {
      return this.$store.getters.loadedItem.state === 'edit';
    },
    okButtonLabel() {
      return this.isEditState ? 'Salva' : 'Crea';
    },
    showErrors() {
      return this.errorMsg.length > 0
    }
  },
  methods: {
    add(modalEvent) {
      modalEvent.preventDefault();
      // validation stuff
      const itemData = this.itemState.item;
      if (itemData.name.length === 0) {
        this.errorMsg = 'Il nome del prodotto è obbligatorio';
        return;
      }
      if (typeof itemData.quantity !== 'number') {
        this.errorMsg = 'La quantità deve essere un numero';
        return;
      }
      this.$store.dispatch('saveItem', Object.assign({}, this.itemState));
      this.item = {};
      this.$nextTick(() => {
        this.$bvModal.hide('item-form');
      });
    },
    cleanMessages() {
      this.errorMsg = '';
    },
    remove() {
      this.$bvModal.msgBoxConfirm('Confermi eliminazione ?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store.dispatch('removeItem', Object.assign({}, this.itemState))
            this.$bvModal.hide('item-form')
          }
        })
        .catch(err => {
          console.log(err)
          this.$bvModal.hide('item-form')
        })
    },
    cancel() {
      this.$bvModal.hide('item-form')
    },
    update({ flag, item }) {
      this.itemState.item.buyNow = flag
    }
  },
  components: {
    BuyNow
  }
}
</script>
