<template>
  <div class="home">
    <Header />
    <SearchBar />
    <AddItem />
    <FilterList />
    <ExtraItemList v-show="buyNowFilter" />
    <ItemList />
    <ItemModal />
  </div>
</template>
<script>
import AddItem from '@/components/AddItem';
import ItemList from '@/components/ItemList';
import ItemModal from '@/components/ItemModal';
import SearchBar from '@/components/SearchBar';
import FilterList from '@/components/FilterList';
import ExtraItemList from '@/components/ExtraItemList';
import Header from '@/components/Header';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['buyNowFilter'])
  },
  components: {
    AddItem,
    ItemList,
    ItemModal,
    SearchBar,
    FilterList,
    ExtraItemList,
    Header
  }
};
</script>
