<template>
  <b-icon icon="cart3" @click.stop="toggleBuyNow" :class="{ buy: isToggled }"></b-icon>
</template>

<script>
export default {
  props: {
    flag: {
      required: true
    },
    item: {
      type: Object
    }
  },
  data() {
    return {
      buyNow: this.flag || false
    }
  },
  computed: {
    isToggled() {
      return this.flag || false
    }
  },
  methods: {
    toggleBuyNow() {
      this.buyNow = !this.buyNow
      this.$emit('buy-now', { flag: this.buyNow, item: this.item })
    }
  }
}
</script>

<style scoped>
.buy {
  color: #51ea22;
}
</style>
