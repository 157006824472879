<template>
  <div>
    <b-card title="Fuori Cambusa">
      <b-card-text>
        <b-input-group>
          <b-input v-model="extraItem" />
          <b-button @click="add">
            <b-icon icon="plus"></b-icon>
          </b-button>
        </b-input-group>
      </b-card-text>
      <b-card-text>
        <b-row class="mt-5">
          <b-col>
            <b-list-group>
              <b-list-group-item v-for="(item, index) in extraItemList" :key="item.id" class="d-flex justify-content-between align-items-center">
                {{ item.name }}
                <b-button variant="danger" @click="remove(item, index)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  created() {
    this.fetchExtraItems()
  },
  data() {
    return {
      extraItem: ''
    }
  },
  computed: {
    ...mapGetters([
      'extraItemList'
    ])
  },
  methods: {
    add() {
      const item = { name: this.extraItem }
      this.saveExtraItem(item)
      this.extraItem = ''
    },
    remove(item, index) {
      console.log(JSON.stringify(item))
      this.removeExtraItem({ item, index })
    },
    ...mapActions([
      'saveExtraItem',
      'removeExtraItem',
      'fetchExtraItems'
    ])
  }
}
</script>
