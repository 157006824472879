<template>
  <div class="home">
    <p>
      👋 Benvenuto in Cambusa.
    </p>
    <p>
      Registra una nuova cambusa per gestire la tua dispensa.
    </p>
    <p>
      ⚠️ <strong>Ricorda</strong>: Cambusa non richiede e-mail nè altri riferimenti personali,
      quindi annota la password che sceglierai perchè non c'è modo di recuperarla.
    </p>
    <b-alert variant="success" v-if="registrationOk" show>Registrazione avvenuta con successo, ora puoi autenticarti  <b-link to="/login">qui</b-link></b-alert>
    <b-alert variant="danger" v-if="errMsg.length > 0" show>{{errMsg}}</b-alert>
    <b-alert variant="success" v-if="!isRegistrationOpen" show>
      Grazie per essere interessato a Cambusa 🙏.
      In questo momento la registrazione è chiusa, ma verrà riaperta a breve.
      Torna a trovarci 🚀.
    </b-alert>
    <b-form v-if="isRegistrationOpen">
      <b-form-group label="Nome Cambusa" label-for="input-nome">
        <b-form-input id="input-nome" v-model="cambusaName" trim></b-form-input>
      </b-form-group>
      <b-form-group label="Password" label-for="input-passwd">
        <b-form-input
          type="password"
          id="input-passwd"
          v-model="password"
          trim
        ></b-form-input>
      </b-form-group>
      <b-button @click="register" class="float-right" variant="primary"
        >Crea</b-button
      >
    </b-form>
  </div>
</template>

<script>
import userService from '../services/userService';
export default {
  mounted() {
    userService.isRegistrationOpen()
      .then(data => { this.isRegistrationOpen = data })
      .catch(_ => { this.errMsg = 'Problemi con la registrazione in questo momento, riprova più tardi'; this.isRegistrationOpen = false; })
  },
  data() {
    return {
      cambusaName: '',
      password: '',
      registrationOk: false,
      errMsg: '',
      isRegistrationOpen: true
    };
  },
  methods: {
    register() {
      this.registrationOk = false
      this.errMsg = ''
      this.$store.dispatch('register', {
        user: this.cambusaName,
        passwd: this.password
      })
        .then(res => { this.registrationOk = true })
        .catch(_ => { this.errMsg = 'Registrazione fallita..riprova per favore'; })
        .finally(_ => { this.cambusaName = ''; this.password = '' })
    }
  }
};
</script>

<style>
.home {
  margin-top: 10px;
}
</style>
