<template>
  <b-row class="mt-5">
    <b-col>
    <b-list-group>
      <b-list-group-item @click="edit(index)" v-for="(item, index) in itemList" :key="item.id" class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column">
          {{ item.name }}
          <span class="last-mod">{{item.lastMod | fdate}}</span>
        </div>
        <div>
          <buy-now :flag="item.buyNow" :item="item" @buy-now="update" />
          <b-badge pill class="ml-2">{{item.quantity}} </b-badge>
        </div>
      </b-list-group-item>
    </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import BuyNow from '@/components/BuyNow.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { BuyNow },
  computed: {
    ...mapGetters([
      'itemList'
    ])
  },
  methods: {
    edit(idx) {
      this.loadItem({ state: 'edit', item: Object.assign({}, this.itemList[idx]), index: idx })
      this.$bvModal.show('item-form');
    },
    update({ flag, item }) {
      const itemIndex = this.itemList.findIndex(i => i._id === item._id)
      item.buyNow = flag
      this.saveItem({ state: 'edit', item: item, index: itemIndex })
    },
    ...mapActions([
      'fetchItems',
      'loadItem',
      'saveItem'
    ])
  },
  mounted() {
    this.fetchItems()
  }
};
</script>

<style>
.last-mod {
  font-size: 0.8em;
}
</style>>
